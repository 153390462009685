export default {
    data() {
        return {
            products: [
                {
                    id: 1,
                    title: 'Gooseneck Tablet Holder Stand for Bed: Tryone Adjustable Flexible Arm Tablets Mount Clamp on Table Compatible with iPad Air Mini | Galaxy Tabs | Kindle Fire | Switch or Other 4.7 -10.5" Devices',
                    price: "$16.99",
                    category: "Gooseneck Tablet Holder",
                    url: 'https://www.amazon.com/Tryone-Gooseneck-Nintendo-Samsung-Overall/dp/B01AUQ33LG',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61pnnei-pAL.jpg'
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71ob5fzs6LL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71YF4Uh0tJL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71edezqUXCL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71SPVVjmKuL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71CB1XgC5FL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/v2/3be7f345-f7de-5cdb-963c-6d2fddee2579/ShortForm-Generic-480p-16-9-1409173089793-rpcbe5.mp4',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, White",
                        "Compatible Devices": "Apple-ipad-air, Apple-ipad-air-2, Apple-ipad-mini",
                        "Compatible Phone Models": "Iphone",
                        "Special Feature": "Adjustable",
                        "Material": "Aluminum",
                        "ASIN": "B01AUQ33LG",
                        "SKU": "ANJ-30",
                    },
                    tips: [
                        '【Statement:】Kindly note that this item is suitable for viewing or reading only. It will shake quite a bit and make you uncomfortable if you need to touch or tap the device frequently. However it is inevitable due to some law of physics.',
                        '【Using Tips:】1.When you are holding a heavy tablet, you could try to loop the gooseneck into a "S" shape or circle shape which would provide some more stability. 2.Bend using two hands and bend just the gooseneck when adjusting the position because it is stiff. Pull the device violently when adjusting may broke the nut. 3.Also, don\'t overtighten the base clamp too much so it won\'t break easily.',
                        '【Reinforced Bolt Clamp Base:】Reinforced and thickened base. Won’t break easily. Fit for 3 inches(75mm) thickness countertop or headboard.',
                        '【Flexible Stiffened Arm:】Made of Aluminium-Magnesium Alloy, stiffened material, very sturdy and flexible. 30in Overall Length from bottom to top.',
                        '【Compatibility:】Compatible with any devices from diagonal 4.7 to 10.5 inches.'
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/fc4e454b-d4a8-46a4-bcdf-aca2240e8a5d.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/ddcba839-46bc-4c4b-b1f1-1cfc03c8c5f1.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/5dbd27c8-b531-49ff-a7a5-6b0c7ecfbee0.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Nintendo Switch owner’s favorite",
                                    text: "Great for enjoying Switch games in bed or on sofa, free your hands from tiredness, especially when lying."
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/68f77b92-ad46-4556-880d-c282e4157ac4.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Good partner in Kitchen",
                                    text: "When learning cooking through video teaching, cooperate with this gooseneck holder is perfect. Don’t worry about soiling the smart phones"
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/441c15e8-3820-4369-a696-3b7fe60051fa.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Improve efficiency",
                                    text: "The tablet mount could keep your tablet in the air as a secondary screen. You could adjust it to the best viewing angle to free your hands and start working or studying."
                                },
                            ],
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/381589d2-7a49-4b74-9884-d81865d30e61.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Watching videos comfortably",
                                    text: "With the gooseneck holder attached to the table, you’re totally free your hands from holding your phone or tablet and watch videos comfortably."
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/71d2b665-124a-4f0d-9759-8ff439cc2d9c.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Free your hands",
                                    text: "With this lazy holder clamped to nightstand or headboard, you could enjoy a great book, watch Netflix lying comfortably."
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/ff1da5dd-c1b9-431a-bae7-d4fbf25de04f.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Great for video recording",
                                    text: "The gooseneck tablet stand is great for video recording or live streaming. With it's flexible arm, you could adjust it easily for a comfortable angle as you need."
                                },
                            ],
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/0547e7dc-0267-40b6-baff-f698f13b0e88.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 2,
                    title: 'Gooseneck Tablet Holder Stand for Bed: Tryone Adjustable Long 39.4inch Flexible Lazy Arm Tablets Mount Compatible with iPad Pro Air Mini | Galaxy Tab | Kindle Fire | Switch or Other 4.7 -12.9" Device',
                    price: "$22.12 - $25.99",
                    category: "Gooseneck Tablet Holder",
                    url: "https://www.amazon.com/Gooseneck-Tablet-Holder-Stand-Bed/dp/B098X7PMWJ",
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61HW8sceCRL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71Zy1CEUweL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/719dvvM8kOL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61W2X8Y+J6L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61uA-g4RDRL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71xYoXwV1zL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71CGjdlkUnL.jpg',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Bright Black, White",
                        "Compatible Devices": "IPad mini 2 3 4 5,iPad Pro 9.7 10.5 11 12.9, iPad Air 2 3 4, Galaxy tabs, Switch, Fire HD tab etc",
                        "Mounting Type": "Tabletop",
                        "Material": "Silicone",
                        "Special Feature": "Adjustable",
                        "ASIN": "B098X7PMWJ",
                        "SKU": "ANJ-104",
                    },
                    tips: [
                        '【Tips❗❗❗】 Please note that due to its unique physical characteristics, the gooseneck bracket will produce unavoidable shaking while taking into account its flexibility and strength. This may happen when you are operating a tablet. Try looping the gooseneck arm near the stand into an "s" shape, it will reduce the bounce and wobble to some extent. You can use your hand to go stop or let it stop shaking by itself, this kind of shaking is normal, please understand',
                        '【Longest Gooseneck Stand】 We made a new upgrade for Tryone gooseneck tablet holder, we used stronger materials and upgraded the total length to 39.74inch/100cm while ensuring stability, it is currently the longest gooseneck tablet holder on the market',
                        '【Free Your Hands】 The gooseneck tablet holder is a good helper for you to use your tablet in various scenarios, people use it to hold up the tablet for watching movies, playing games, reading, viewing recipes, etc., freeing your hands and spending a relaxing time',
                        '【Sturdy Base】 Gooseneck tablet holder mount can be installed on the headboard, bedside table, desks, chair, coffee table, kitchen countertop, etc. The maximum adjustment range of the base can reach 2.56 inches/6.5cm, and the contact surface is equipped with two protective pads, so there is no need to worry about damaging the furniture',
                        '【Wide Compatibility】 The tablet holder has a wide range of compatibility, suitable for almost all 4.7-12.9inch devices on the market, such as iPad mini 2 3 4 5,iPad Pro 9.7 10.5 11 12.9, iPad Air 2 3 4, Galaxy tabs, Switch, Fire HD tab etc. In addition, the tablet phone is equipped with silicone pads, which will not damage your device while preventing slippage',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/809fe043-4de3-4ac1-982a-b9d92ecbb585.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/318b01c6-df8a-40ee-bdc4-e9b3fc2cf26f.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/b6420756-5944-4e22-9361-13907866d329.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/8d20379f-7742-4fc0-b31c-705efe76972a.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/a4b0534a-fe5d-4872-a4bf-03ebde1c084c.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/e44245a7-b784-48e5-9d49-c2fb488b239e.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/74152a40-0340-4f5a-8ac2-4feb2dd22085.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 3,
                    title: 'Gooseneck Phone Holder Stand for Bed: Tryone Flexible Arm Adjustable Cell Phones Mount Clamp on Desk Compatible with iPhone 12 Pro 12 11 Pro Xs 8 7 6 | Samsung S21 or Other 4"-7" Devices',
                    price: "$12.00 - $14.35",
                    category: "Gooseneck Phone Holder",
                    url: "https://www.amazon.com/Tryone-Gooseneck-Flexible-Compatible-Smartphones/dp/B0777JP14F",
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61wVuBs37pL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/713ByLM-ZrL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71eFqQgxXwL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61SA-SvyUOL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61AgOWjIgnL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71L0FXnyx8L.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/v2/819135ff-4118-55ef-bf48-8aa9dc78a362/ShortForm-Generic-480p-16-9-1409173089793-rpcbe5.mp4',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, White",
                        "Compatible Devices": "Smartphones",
                        "Compatible Phone Models": "Ios or Android phones",
                        "Mounting Type": "Phone Mount,Clamp Mount,Tabletop,Phones",
                        "Material": "Acrylonitrile Butadiene Styrene",
                        "ASIN": "B0777JP14F",
                        "SKU": "ANJ-18",
                    },
                    tips: [
                        '【Note:】If you are using a thick case, then it won\'t be compatible with the spring-grip. Make sure the whole size is within the limit. Please view the measurement image of the spring-grip for reference.',
                        '【Size Requirement of Phone:】Max width should be about 3in(76mm). Max thickness should be about 0.5in(13mm).',
                        '【Length:】27.5in(70cm) overall length with 21.5in(55cm) flexible arm.',
                        '【Screw Clamp:】Fit for 3 inches(75mm) thickness countertop or headboard.',
                        '【Material:】Aluminium-Magnesium Alloy; ABS plastic.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-seller-content-images-us-east-1/ATVPDKIKX0DER/A29V4QFPJPYCZC/fe435c72-3b68-4c90-ba2a-e8a25aa6010b._CR0,0,600,180_PT0_SX600__.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-seller-content-images-us-east-1/ATVPDKIKX0DER/A29V4QFPJPYCZC/B0777JP14F/5jQPlNWRH2k._UX970_TTW__.jpg',
                        },
                    ]
                },
                {
                    id: 4,
                    title: 'Gooseneck Phone Holder Stand for Bed: Tryone Flexible Long 35-inch Arm Adjustable Cell Phones Mount Clamp on Desk Compatible with iPhone 12 Pro 12 11 Pro Xs 8 7 6 | Samsung S21 or Other 4"-7" Devices',
                    price: "$22.99",
                    category: "Gooseneck Phone Holder",
                    url: "https://www.amazon.com/Gooseneck-Phone-Holder-Stand-Bed/dp/B095CFK7GX",
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/51GjTqLNB-L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/612P+IwjHqS.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71eoU0UIKcS.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61IjSIaIfkS.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61-hpt+EJTS.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71+XVkeq5JS.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71NSpIEDReS.jpg',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black",
                        "Compatible Devices": "Smartphones",
                        "Compatible Phone Models": "Google Pixel,S10,Note 9,Iphone",
                        "Mounting Type": "Phone Mount,Clamp Mount,Tabletop,Phones",
                        "Special Feature": "Adjustable",
                        "ASIN": "B095CFK7GX",
                        "SKU": "ANJ-106",
                    },
                    tips: [
                        '【Long Flexible Arm】 Tryone 2021 new upgraded gooseneck mobile phone holder adopts the most scientific gooseneck length, after a lot of experiments, it has been proved that the 35inch/90cm length is the most scientific, ensuring that the gooseneck can be adjusted at will, stably support your smartphone, will do not sag after long-term use',
                        '【Multifunctional Cell Phone Holder】 Gooseneck smart phone holders are often used in many scenarios, such as: lying on the sofa to watch movies, lying on the bed to listen to music, reading recipes in the kitchen, video chatting with family members on the table, and various selfies in life, Which is also a stand for video shooting above the head, let you record the beautiful moments in your life at any time',
                        '【Sturdy base】 Tryone gooseneck cell phone holder has a larger and stronger base, the maximum adjustment range can reach 2.56inch/6.5cm, you can clamp it firmly on the bed frame, headboard, headboard, bedside table, desk, coffee table, kitchen counter, etc, the contact surface is equipped with two silicone pads, so you don’t have to worry about damaging your furniture',
                        '【Wide Compatibility】 The phone clamp has a wide range of compatibility, suitable for almost all 4.7-7inch mobile phones on the market, such as iPhone 12 Mini 11 Pro XS Max XS XR X 8 Plus 8 7 Plus 7 6S Plus 6S 6 SE, Galaxy S10e S10+ S10 S9+ S9 S8+ S8 S7 Edge Note 9 8, LG, Moto, Google Pixel, Moto, etc. In addition, the phone clip is equipped with two silicone pads, which will not damage your phone while preventing slippage',
                        '【High-strength material】 The gooseneck of Tryone phone holder is made of high-strength aluminum alloy, after a large number of strength tests, it is easy to bend and support stability during use, and does not sag during long-term use',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/5bfa3766-6750-47f6-8c70-9277b48aaca4.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/7ad27c0c-9b8f-49b7-97bc-fb9ae811dfa1.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/3b4e634f-7828-4c56-ab18-ef198d3a6723.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/a89b10fe-431a-4a01-b800-4990d05838df.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/019afcf8-a28e-4648-8c43-0819883aa8dd.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/2408f8ad-63b2-4c48-8840-46475b7d0405.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/abe5b1dc-67f3-4781-bcc4-38cde7fcc553.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 5,
                    title: 'Tryone Tablet Stand Holder for Desk: 360 Degree Rotating Adjustable Desktop Stands Clamp Mount Compatible with iPad, iPhone, Switch, Galaxy Tab, Other 4.7"-11" Screen Devices and All Phones',
                    price: "$19.99",
                    category: "Desktop Tablet Holder",
                    url: "https://www.amazon.com/Tablet-Stand-Holder-Rotating-Nintendo/dp/B08JG2QM3N",
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61QF6GQqoSL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71sHb8qTumL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/711H2PxbZhL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/714G0btVL9L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61-TJ3TWyLL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/613pxFh07yL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/b49295fd-909d-4393-8d68-629929c18367/default.jobtemplate.mp4.480.mp4',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "White",
                        "Compatible Devices": "Smartphones, Tablets, Desktop",
                        "Compatible Phone Models": "Google Pixel,Iphone,Nexus",
                        "Mounting Type": "Phone Mount,Clamp Mount,For,Desk,Tabletop",
                        "Material": "Aluminum",
                        "ASIN": "B08JG2QM3N",
                        "SKU": "ZM-15",
                    },
                    tips: [
                        '【Long Flexible Arm】 Tryone 2021 new upgraded gooseneck mobile phone holder adopts the most scientific gooseneck length, after a lot of experiments, it has been proved that the 35inch/90cm length is the most scientific, ensuring that the gooseneck can be adjusted at will, stably support your smartphone, will do not sag after long-term use',
                        '【Multifunctional Cell Phone Holder】 Gooseneck smart phone holders are often used in many scenarios, such as: lying on the sofa to watch movies, lying on the bed to listen to music, reading recipes in the kitchen, video chatting with family members on the table, and various selfies in life, Which is also a stand for video shooting above the head, let you record the beautiful moments in your life at any time',
                        '【Sturdy base】 Tryone gooseneck cell phone holder has a larger and stronger base, the maximum adjustment range can reach 2.56inch/6.5cm, you can clamp it firmly on the bed frame, headboard, headboard, bedside table, desk, coffee table, kitchen counter, etc, the contact surface is equipped with two silicone pads, so you don’t have to worry about damaging your furniture',
                        '【Wide Compatibility】 The phone clamp has a wide range of compatibility, suitable for almost all 4.7-7inch mobile phones on the market, such as iPhone 12 Mini 11 Pro XS Max XS XR X 8 Plus 8 7 Plus 7 6S Plus 6S 6 SE, Galaxy S10e S10+ S10 S9+ S9 S8+ S8 S7 Edge Note 9 8, LG, Moto, Google Pixel, Moto, etc. In addition, the phone clip is equipped with two silicone pads, which will not damage your phone while preventing slippage',
                        '【High-strength material】 The gooseneck of Tryone phone holder is made of high-strength aluminum alloy, after a large number of strength tests, it is easy to bend and support stability during use, and does not sag during long-term use',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/6bdd2670-cd01-44d1-a0f8-935456e00a9f.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: "text",
                            data: [
                                {
                                    type: "title",
                                    message: "Tryone Hands free phone/ iPad/ tablet stand holder",
                                },
                                {
                                    type: "subtitle",
                                    message: "Flexible Arm & 360 Degree Rotating holder",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "The tablet stand has a flexible gooseneck arm with a 360-degree swivel ball head, bendable rod 180 degree adjustable, which can provide you with the most comfortable viewing angles .",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Wide Compatibility:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Tryone Desktop Stand is compatible with 4.7~11inch screen mobile phones and tablets, including Nintendo Switch, iPad Pro 11/10.5 / 9.7, iPad 1/2 / 3/4, iPad Air 2, iPad Mini 1/2 / 3/ 4, Microsoft Surface Pro series, Samsung Galaxy Tab series and so on",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Simple Your Life:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Perfect for watching movies/live show, reading visual novel,making a hands-free call/Face Time call/video chat while having a meal/washing closes or dishes/brushing teeth/studying/working",
                                    ],
                                },
                            ]
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/b9d4983c-4cc3-4f03-8de7-e0fbe6da059c.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/200be90f-6337-46a7-877a-364edea57815.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/c3d6aed5-e6d4-4713-8329-68c6e21bf6d2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/3eaf882e-743b-4526-ae09-caef822ef0a2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/4c762421-3407-4154-b3c9-3ed1149d2e66.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/fffc92ab-cc13-4049-8e60-4178e8369cc2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 6,
                    title: 'Tablet Holder Stand – Tryone Adjustable iPad Holder Stand with Adjustable Height & 360 Degree Rotating, Desktop Tablet Stand for iPad/iPhone/Nintendo Switch/Samsung Galaxy Tabs and More(4-12.9\'\')',
                    price: "$24.99",
                    category: "Desktop Tablet Holder",
                    url: "https://www.amazon.com/Tablet-Holder-Stand-Adjustable-Rotating/dp/B08B15CR4C",
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/51ftzYoVUtL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71rIxeb8qvL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71nBYpqIlML.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/81+5JL9KtjL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61RBlurDVNL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61h7+4WbtGL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/01825ef8-aa5e-4c43-af63-f68e69988352/default.jobtemplate.mp4.480.mp4',
                        }
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "White",
                        "Compatible Devices": "Apple ipad pro",
                        "Compatible Phone Models": "Google Pixel,Iphone,Nexus",
                        "Mounting Type": "Tabletop",
                        "Material": "Aluminum",
                        "ASIN": "B08B15CR4C",
                        "SKU": "ZM-17",
                    },
                    tips: [
                        '【Strong Arm】Made by premium solid aluminum alloy, the flexible arm and extension tube are more durable than usual. The strong gooseneck arm makes the tablet stand holder super stable to support all tablets and phones and without worry about droop .',
                        '【Stable Stand】Strong aluminum alloy base makes the tablet stand more stable.Tryone desktop tablet holder provides safe and secure support for your device.',
                        '【Flexible Adjustment】This iPad holder stand is 360 degree rotatable and height adjustable,which can provide you with the most comfortable viewing angles and ergonomic eye-level height.',
                        '【Broad Usage Place】Great tablet accessories in the office, school and house. The iPad stand holder is perfect for watching videos, viewing photos, reading, video recording, playing game,working,live streaming.',
                        '【Excellent Compatibility & Case Friendly】Desktop tablet stand is compatible with 4"- 11" cellphones, tablets and other devices, including iPad Pro 9.7, 10.5, iPad Air, iPad mini 2 3 4, iPhone 11 Pro XS Max XR X 6 7 8 Plus, surface Pro, E-readers, Samsung Galaxy Tab, Google Pixel C, Nexus 7 9, Nintendo Switch.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/5a77092b-70b4-416a-8a0d-1385163fbfa9.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: "text",
                            data: [
                                {
                                    type: "title",
                                    message: "Tryone Hands Free Phone/ iPad/ Tablet Stand Holder",
                                },
                                {
                                    type: "subtitle",
                                    message: "Flexible Arm and Height Adjustable:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Height adjustable from 13\" to 19.69\", angle 360 degree rotatable, bendable rod 180 degree adjustable, which can provide you with the most comfortable viewing angles and ergonomic eye-level height.",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Wide Compatibility:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Tryone Desktop Stand is compatible with 4.7~12.9 inch screen mobile phones and tablets, including Nintendo Switch, iPad Pro 10.5 / 9.7, iPad 1/2 / 3/4, iPad Air 2, iPad Mini 1/2 / 3/ 4, Microsoft Surface Pro series, Samsung Galaxy Tab series and so on",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Simple Your Life:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Perfect for watching movies/live show, reading visual novel,making a hands-free call/Face Time call/video chat while having a meal/washing closes or dishes/brushing teeth/studying/working.",
                                    ],
                                },
                            ]
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/1f969c18-94c8-4719-9fb5-b9e296395424.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/b324a2a0-25e2-4ec4-a39f-30e52b048135.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/95940c90-d6ce-4fb0-b2eb-b03fd57d4e5a.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/e0d5eca9-f80f-4a17-b658-a7ea807a3fd4.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/3eaf882e-743b-4526-ae09-caef822ef0a2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/98d2e87f-088e-4175-b82f-134d89766b30.__CR0,0,970,300_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 7,
                    title: 'Tryone Shower Phone Holder - Universal Wall Mount Cellphone Stand for Bathroom Mirror/ Bedside/ Kitchen/ Recording Compatible with iPhone iPad Series/ Samsung Galaxy Smartphone Tablet/ Switch OLED',
                    price: "$14.99",
                    category: "Wall Phone Holder",
                    url: 'https://www.amazon.com/Tryone-Shower-Phone-Holder-Compatible/dp/B0B9B746XT',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/51QcwJ5rLYL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61fGOER5zSL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71F5ul6lUWL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71rAb0eIUaL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61Ym17ao2+L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71gMosjvECL.jpg',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, White",
                        "Compatible Devices": "Apple ipad mini, Apple iphone 4",
                        "Compatible Phone Models": "Android Cellphones/ iPhones, Switch/ Switch Lite/ Switch OLED, Android Tablets/ iPads",
                        "Mounting Type": "Wall Mount",
                        "Material": "Acrylonitrile Butadiene Styrene",
                        "ASIN": "B0B9B746XT",
                        "SKU": "B86",
                    },
                    tips: [
                        '【Strong Arm】Made by premium solid aluminum alloy, the flexible arm and extension tube are more durable than usual. The strong gooseneck arm makes the tablet stand holder super stable to support all tablets and phones and without worry about droop .',
                        '【Stable Stand】Strong aluminum alloy base makes the tablet stand more stable.Tryone desktop tablet holder provides safe and secure support for your device.',
                        '【Flexible Adjustment】This iPad holder stand is 360 degree rotatable and height adjustable,which can provide you with the most comfortable viewing angles and ergonomic eye-level height.',
                        '【Broad Usage Place】Great tablet accessories in the office, school and house. The iPad stand holder is perfect for watching videos, viewing photos, reading, video recording, playing game,working,live streaming.',
                        '【Excellent Compatibility & Case Friendly】Desktop tablet stand is compatible with 4"- 11" cellphones, tablets and other devices, including iPad Pro 9.7, 10.5, iPad Air, iPad mini 2 3 4, iPhone 11 Pro XS Max XR X 6 7 8 Plus, surface Pro, E-readers, Samsung Galaxy Tab, Google Pixel C, Nexus 7 9, Nintendo Switch.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/5a77092b-70b4-416a-8a0d-1385163fbfa9.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: "text",
                            data: [
                                {
                                    type: "title",
                                    message: "Tryone Hands Free Phone/ iPad/ Tablet Stand Holder",
                                },
                                {
                                    type: "subtitle",
                                    message: "Flexible Arm and Height Adjustable:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Height adjustable from 13\" to 19.69\", angle 360 degree rotatable, bendable rod 180 degree adjustable, which can provide you with the most comfortable viewing angles and ergonomic eye-level height.",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Wide Compatibility:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Tryone Desktop Stand is compatible with 4.7~12.9 inch screen mobile phones and tablets, including Nintendo Switch, iPad Pro 10.5 / 9.7, iPad 1/2 / 3/4, iPad Air 2, iPad Mini 1/2 / 3/ 4, Microsoft Surface Pro series, Samsung Galaxy Tab series and so on",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Simple Your Life:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Perfect for watching movies/live show, reading visual novel,making a hands-free call/Face Time call/video chat while having a meal/washing closes or dishes/brushing teeth/studying/working.",
                                    ],
                                },
                            ]
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/1f969c18-94c8-4719-9fb5-b9e296395424.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/b324a2a0-25e2-4ec4-a39f-30e52b048135.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/95940c90-d6ce-4fb0-b2eb-b03fd57d4e5a.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/e0d5eca9-f80f-4a17-b658-a7ea807a3fd4.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/3eaf882e-743b-4526-ae09-caef822ef0a2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/98d2e87f-088e-4175-b82f-134d89766b30.__CR0,0,970,300_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 8,
                    title: 'Wall Mount Cell Phone Holder - Mirror Adjustable Cellphone Stand with 2Pcs Adhesive Sticky Pads for Bathroom Bedroom Kitchen or Dorm, Compatible with iPhone Series or Other Smartphones',
                    price: "$12.99",
                    category: "Wall Phone Holder",
                    url: 'https://www.amazon.com/Wall-Mount-Phone-Holder-Compatible/dp/B085TBTZYZ',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61owRQEGnvL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61jDusrHrML.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71WtjTtCFhL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61YeuRC-CpL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71LOjctcBIL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71s9O6McJAL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71otDz0llrL.jpg',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, Silver",
                        "Compatible Devices": "Smartphones, Tablets",
                        "Compatible Phone Models": "All Models",
                        "Mounting Type": "Phone Mount,Adhesive,Wall Mount",
                        "Material": "Stainless Steel, Aluminum, Glass, Rubber",
                        "ASIN": "B085TBTZYZ",
                        "SKU": "B10",
                    },
                    tips: [
                        '【Smart Lifestyle】The wall phone holder is a good companion on your office, kitchen, bedroom, desk. Perfect for watching videos, video chat, cooking, viewing photos and free your hands.',
                        '【Sturdy Construction】Made by aluminium alloy material, soft rubber cushions and newly upgraded adhesive. It is sturdy, firm, durable and environmentally-friendly. (Hold up to 2 lbs)',
                        '【Easy Installation】 Mount to your wall with smooth surface, such as smooth wallpaper, lime wall, tile, stainless steel, glass, wooden and metal surface',
                        '【Universal Compatibility】 Suitable for iPhone and smartphones between 4 and 8 inches, like iPhone 11 Pro XS Max XR X 8 7 6 6S 5 5S 4S SE Plus, Nintendo Switch, Huawei, Samsung Galaxy S8 S7 S6, Note 6 5, LG, Sony, Nexus and ect.',
                        '【What You Get】1*Tryone Wall Phone Mount, 2*Upgraded Adhesive.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/9749c83b-bc81-47b3-9a2d-74e70989de83.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/1dd64f4d-fdf6-44b1-829e-121380643d75.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/3f98f0ee-0ae0-432c-8888-44683c430578.__CR0,0,970,300_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/b5f3e42e-f2e0-421c-a73e-bd3a4a83e528.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Without Blocking Subtitles",
                                    text: "Considerate design to make sure that without blocking subtitles when watching movies."
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/08681c19-2e2d-4295-86bb-a72f4107b5ce.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Reserved Charging Hole",
                                    text: "Featured with reserved charging hole, this wall phone holder mount is convenient for charging."
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/88fc4281-5824-47f1-bb46-f36e84871edf.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Charging Cable Organizer",
                                    text: "When it’s not in use, you could use it as charging cable organizer to make sure your desk neat and tidy."
                                },
                            ],
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/7eae8b86-e361-473a-9a01-76c2b18d7478.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/6f9406a4-ea15-4cad-8b41-41ecff89769d.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 9,
                    title: 'Waterproof Shower Phone Holder Mount - Tryone 360° Rotating Bathroom Wall Cellphone Mountable Case, Shower Magic Box Compatible with iPhone 12 Pro 12 11 Pro Xs 8 | Samsung S21 or Other 4"-6.8" Device',
                    price: "$16.99",
                    category: "Wall Phone Holder",
                    url: 'https://www.amazon.com/Waterproof-Shower-Phone-Holder-Mount/dp/B09NVW81K5',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61IC0rnFV1L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71r0hp08seL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/710F8HQ33RL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/715lGnoUjYL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61oHFX5--hL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/711klgPB5VL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/b3d8f128-5785-478a-b8d2-c87cd11d2b8e/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, White",
                        "Compatible Devices": "IPhone 13 / 13 Pro / 13 Pro Max / 13 mini/ iPhone 12 / 12 Pro / 12 Pro Max / 12 mini iPhone XS Max XR X, iPhone 8Plus; Google Pixel 3a, pixel 3, pixel 3 xl, pixel 2 xl, Nexus 5x 6p; Samsung Galaxy S10+/S10/S10e/A60/A50/A40/A30/A20e/A20/Note9/S9+/S9/M30s/A30s/A50s/M40/M30/M20; LG G5 G6 G7 V20 V30 V40 V50 ThinQ Q70; Nintendo Switch, Microsoft Lumia 950 XL, HTC 10 U12+, BLU G9 Pro, Motorola One Zoom, iPhone 11 / 11 Pro / 11 Pro Max / XS / Max / XR / X / 8 / 7 / 6 / Plus; Galaxy S10e / S10 / S10+ / S9+ / S9 / S8+ / S8 / S7, Note 9 / Note 8 / Huawei P10 / Mate 9 / Mate 20X / Mate 20 Pro; LG G7 / V30+ / Switch / Google Pixel / Pixel 3a / XL / 3 / 2XL / 2, Nexus 5X / 6P, Sony XZ2 Premium, Sony XZ3",
                        "Compatible Phone Models": "IPhone",
                        "Mounting Type": "Wall Mount",
                        "Special Feature": "Waterproof",
                        "ASIN": "B09NVW81K5",
                        "SKU": "B36",
                    },
                    tips: [
                        '【Waterproof Phone Holder】 The shower phone holder is made of high-quality ABS material, and there is waterproof silicone inside the box, which can reach 100% splashproof water, enjoy watching movies, Tiktok or others while bathing. Note: do not soak the shower phone holder in water',
                        '【Anti-fog HD Touch Screen】 The screen of the Shower phone holder is made of high-quality TPU material, is waterproof, anti-fog and sensitive to touch, it has a light transmittance of 99%, even if the fog appears during the shower, it will not affect the normal use of the phone, and you can accurately control your phone when your hand is wet',
                        '【Use in Multiple Scenarios】 The package contains 3 adhesive pads, so you can use the wall mount bracket to other places by moving the bracket, such as kitchen, living room, bedroom, vanity mirror, next to the bathtub or other, in addition, you can also place the phone holder directly on the desktop to use',
                        '【360°Rotating Design】The shower phone holder supports 360° flip adjustment, 90° tilt angle adjustment, so you can adjust it to a suitable angle to use your phone, the phone holder has a strong load-bearing capacity, so you don’t need to worry about your phone falling',
                        '【Wide Compatibility】 The shower phone holder has a wide range of compatibility, suitable for almost all 4.7-6.8inch mobile phones on the market, such as iPhone 12 Mini 11 Pro XS Max XS XR X 8 Plus 8 7 Plus 7 6S Plus 6S 6 SE, Galaxy S10e S10+ S10 S9+ S9 S8+ S8 S7 Edge Note 9 8, LG, Moto, Google Pixel, Moto, etc',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/b6a23a4d-e2f9-42f1-a66a-0e98a12cd6db.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/80397a7a-5ff4-4a2e-a213-6cf3c183fe2d.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'text',
                            data: [
                                {
                                    type: "subtitle",
                                    message: "Tryone Waterproof Shower Phone Holder Mount-360° Rotating Bathroom Wall Cellphone Mountable Case",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Free Your Hands: The wall-mounted shower phone holder is a good companion for you to enjoy life, whether it is bathing or cooking, it can free your hands while protecting your equipment",
                                        "Waterproof and Anti-fog: The shower phone holder is made of high-quality ABS material, and there is waterproof silicone inside the box, which can reach 100% splashproof water, enjoy watching movies, Tiktok or others while bathing. Note: do not soak the shower phone holder in water",
                                        "Support 4-6.8inch Phone: Suitable for almost all 4.7-6.8inch mobile phones on the market, such as iPhone 12 Mini 11 Pro XS Max XS XR X 8 Plus 8 7 Plus 7 6S Plus 6S 6 SE, Galaxy S10e S10+ S10 S9+ S9 S8+ S8 S7 Edge Note 9 8, LG, Moto, Google Pixel, Moto, etc",
                                        "HD Touch Screen: The screen of the Shower phone holder is made of high-quality TPU material, is sensitive to touch, it has a light transmittance of 99%, even if the fog appears during the shower, it will not affect the normal use of the phone, and you can accurately control your phone when your hand is wet",
                                    ],
                                },
                            ]
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/d5602399-3f40-466b-9647-884020288347.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/9fb3b316-0395-40d8-b6b8-418578043eaa.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Various Angle Adjustments",
                                    text: "The shower phone holder supports 360° flip and 90° tilt angle adjustment, which can be adjusted to a suitable angle to use it"
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/97fdf81a-c13b-49bf-a1e0-99d123ae3f15.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Support Desktop Use",
                                    text: "The shower phone holder is not only suitable for walls, but also supports desktop use. After opening the back support, it can be stabilized on the desktop"
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/88ee633e-1dc5-4c6f-8994-99c06be73a6c.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "3Pcs Adhesive Pads",
                                    text: "The package contains 3 adhesive pads, so you can use the wall mount bracket to other places by moving the bracket, such as kitchen, living room, bedroom, vanity mirror, next to the bathtub or other"
                                },
                            ],
                        },
                        {
                            type: 'text',
                            data: [
                                {
                                    type: "subtitle",
                                    message: "How to install a wall phone holder?",
                                },
                            ],
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/4c23d975-5588-496c-86cc-67dd1db46868.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: "1. Use a clean rag to remove water, stains and dust from the wall"
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/00cafef4-a814-4c40-8450-a298471f19b0.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: "2. Tear off the protective film behind the adhesive pad and stick it on the wall"
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/5caff7b6-db6e-4f12-a706-b58801ca7d94.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: "3.Accurately snap the wall phone holder into the adhesive pad to complete the installation"
                                },
                            ],
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/7832ea06-5ccc-404e-b41d-a27d7318a1c4.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/33d5e9bc-8104-47bf-b054-c3e0b344f9f7.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 10,
                    title: 'Car Headrest Tablet Mount Holder - Tryone Auto Backseat Tablets Stand for Kids Compatible with iPad Air Mini/ Cell Phone/ Galaxy Tab/ Kindle Fire Hd/ Switch Lite or Other 4.7 -10.5" Device(Black)',
                    price: "$19.99",
                    category: "Tablet Headrest Mount",
                    url: 'https://www.amazon.com/Car-Headrest-Tablet-Mount-Holder/dp/B074YYYNJ5',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71okWbiItuL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71+Uf66rDSL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61yZDY1LPiL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61sR9cJoBXL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71d1ZwOZxML.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/810zOo4EUnL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/94fde2b5-53e8-4432-85e8-fccc665f3a9a/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black, Red",
                        "Compatible Devices": "Tablets",
                        "Mounting Type": "Headrest",
                        "Special Feature": "Rotatable",
                        "Material": "Faux Leather",
                        "ASIN": "B074YYYNJ5",
                        "SKU": "CHZ-04",
                    },
                    tips: [
                        '【Convenient Design】One-handed installation and release within 2 seconds.',
                        '【Compatibility of the headrest】The distance between two metal posts on your headrest should be 4.9in-5.9in.',
                        '【Compatibility of the device】Compatible with smartphones/ tablets/ Switch 4.7"-10.5". It is the screen diagonal measurement, not the width.',
                        '【Adjustability】Multi-angle rotation to adjust for the best viewing angle.',
                        '【Statement】Whenever there is any quality problem you meet from our product, we will take responsibility and solve it to your satisfaction immediately.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-seller-content-images-us-east-1/ATVPDKIKX0DER/A29V4QFPJPYCZC/B074YYYNJ5/w0N1Y6UdRx26._UX970_TTW__.jpg',
                        },
                        {
                            type: 'text',
                            data: [
                                {
                                    type: "title",
                                    message: "Tryone Car Headrest Tablet Mount Holder Compatible with Smartphones/ Tablets/ Switch 4.7\"-10.5\", Headrest Posts Width 4.9in-5.9in",
                                },
                                {
                                    type: "subtitle",
                                    message: "Features:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "One-handed installation and release within 2 seconds.",
                                        "Multi-angle rotation to adjust for the best viewing angle.",
                                        "Charge hole and headphone jack are all available.",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Compatibility:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "The distance between two metal posts on your headrest should be 4.9in-5.9in.",
                                        "Compatible with smartphones/ tablets/ Switch 4.7\"-10.5\". It is the screen diagonal measurement, not the width.",
                                    ],
                                },
                                {
                                    type: "subtitle",
                                    message: "Materials:",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "ABS Plastic",
                                        "Anti-skip & Anti-scratch Silicone",
                                        "Premium Aluminium Alloy",
                                    ],
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 11,
                    title: 'Car Headrest Tablet Mount Holder - Tryone Stretchable Backseat Tablets Stand for Kids Compatible with iPad Air Mini/ Cell Phone/ Galaxy Tab/ Kindle Fire Hd/ Switch Lite or Other 4.7-10.5" Device',
                    price: "$24.99",
                    category: "Tablet Headrest Mount",
                    url: 'https://www.amazon.com/Car-Headrest-Tablet-Mount-Stretchable/dp/B07WFYXL6K',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71c2B7tiTfL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71sQzA4Et-L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71daoom3wUL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61YQ2trhCOL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/714Wonqrl6L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71hSdKv6jxL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/10c64203-9514-4dc8-aef9-4c790053fd53/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black",
                        "Compatible Devices": "Tablets",
                        "Compatible Phone Models": "Android / IOS Phones",
                        "Mounting Type": "Headrest",
                        "Special Feature": "Rotatable",
                        "ASIN": "B07WFYXL6K",
                        "SKU": "CHZ-06",
                    },
                    tips: [
                        '【Convenient Design】One-handed installation and release within 2 seconds.',
                        '【Compatibility of the headrest】The width between two metal posts on your headrest should be 4.7in-5.9in.',
                        '【Compatibility of the device】Compatible with smartphones/ tablets/ Switch 4.7"-10.5". It is the screen diagonal measurement, not the width.',
                        '【Adjustability】Multi-angle rotation to adjust for the best viewing angle.',
                        '【Extensibility】The stretchable arm can be extended up to 10.6in.',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/9e114bdd-1fb2-44a0-aa28-3548ef89bdc2.__CR0,0,600,180_PT0_SX600_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/e29c7f04-2576-4942-b03e-c9695dd6882e.__CR0,0,970,300_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/b453f02b-bfab-4db1-8ead-2428b168b74b.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media/sc/04a5e091-d590-42f5-b60e-7b36419de862.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/ca39b7f1-54d7-45dc-8208-9f1a0bf20d97.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: null,
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/c03ef889-5b22-41a8-aa83-b53d4d7f98e2.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: null,
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/5b8501c0-988b-4d6b-a9c7-92067ad6700c.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: null,
                                    text: null,
                                },
                            ],
                        },
                        {
                            type: 'fourSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/90133b5e-3b0c-4ddb-a2c4-456e0f82ca97.__CR0,0,300,300_PT0_SX220_V1___.jpg",
                                    title: null,
                                    text: "Preminum Aluminum Alloy Material",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/6f94774f-8b81-435f-82d4-57713f29f5c8.__CR0,0,300,300_PT0_SX220_V1___.jpg",
                                    title: null,
                                    text: "Protective Silicone Pad. Anti-skidding & Anti-scratching.",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/79bcf866-2bc0-4bc0-98ab-8f06ba3a3f9c.__CR0,0,300,300_PT0_SX220_V1___.jpg",
                                    title: null,
                                    text: "Charge hole and headphone jack are all available.",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media/sc/d5842ea3-a187-443b-91a1-34d20e289604.__CR0,0,300,300_PT0_SX220_V1___.jpg",
                                    title: null,
                                    text: "Comfortable Adjustment Screw",
                                },
                            ],
                        },
                    ]
                },
                {
                    id: 12,
                    title: 'Car Headrest Tablet Mount Holder: Tryone Backseat Stand Cradle for Kids Compatible with Phone | iPad Pro Air Mini | Galaxy Tabs | Kindle Fire HD | Switch Lite or Other 4.7 -12.9" Devices',
                    price: "$25.99",
                    category: "Tablet Headrest Mount",
                    url: 'https://www.amazon.com/Car-Headrest-Tablet-Mount-Holder/dp/B0936WFDQP',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/91uhDB92pRL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71dmpLhyLkL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71xjU4jYM1L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/61ErmFQ9CzL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/719BaL+cJ9L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71np9LWo4xL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/f9d62036-4d36-4609-8017-a5ae1b017dbd/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black",
                        "Compatible Devices": "Apple-ipad-air, Apple-ipad-pro, Apple-ipad-mini",
                        "Compatible Phone Models": "Android Phones, IPhone",
                        "Mounting Type": "Headrest",
                        "Special Feature": "Travel, Universal, Kickstand",
                        "ASIN": "B0936WFDQP",
                        "SKU": "CHZ-09",
                    },
                    tips: [
                        '【Double Tablet Clamp】 Tryone car tablet mount holder is the first version equips with two clamps on the market, both the tablet holder mount supports 360° rotation, meet the needs of all backseat passengers',
                        '【Fit Any Car】 Tryone car tablet mount holder is compatible with almost all autos on the market, as long as your headrest metal strip width is 1.5-7.9 inches, you can use it, and it can help you shore up your tablet/mobile phone or Switch, freeing your hands, you can enjoy the convenience and entertainment by tablet mount holder during the travel',
                        '【Wide Compatibility】 Two car mount holders are compatible with all 4.7-12.9 inch devices, such as 12.9-inch iPad Pro, 11-inch iPad Pro, 9.7-inch iPad Air, iPad mini 1 2 3 4, Switch, Galaxy tab, Kindle fire HD, iPhone 12 Pro max to iPhone6 full range of products, and any compatible device can be very firmly fixed on the bracket',
                        '【Scalability】 We have two clamps, one of them has an extendable stick and it can extend to the middle of the two front seats, whether you are travel alone or a family with children travel, it is your road trip essentials stuff, because people use it to soothe kids, sometimes people will also use it to enjoy their leisure personal travel time',
                        '【Toolless Installation】 You can install it easily without any tool, also it will be secure with shockproof silicone pads, Antivibration silicone to ensure that your car will not be damaged, while the tablet holder mount can be firmly fixed so that it can easily support 12.9-inch iPad or other devices',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/52cc494c-efd2-4081-ba1e-c39af1c69e29.__CR0,0,600,180_PT0_SX600_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/684f1b76-2fda-4b7f-99b4-663199cf4c30.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/a35b177e-33a5-4520-83a2-b3a653a5f656.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/a94b281b-bfeb-4365-84e1-0c7f8a441b4a.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'text',
                            data: [
                                {
                                    type: "subtitle",
                                    message: "Tryone Car Headrest Tablet Mount Holder for Kids Apply to Phone | iPad Pro Air Mini | Galaxy Tabs | Kindle Fire HD | Switch Lite or Other Equipment",
                                },
                                {
                                    type: "text",
                                    message: [
                                        "Whether you are planning a solo trip or a trip with a few children in the back seat of the car, this car tablet holder can meet all your requirements, it can show the tablet/mobile phone to people sitting in the back seat of the car",
                                        "It has two clips, you can stretch one of the clips to the middle of the car at will, so that it can meet the entertainment requirements of everyone, you can free your hands, which will make your journey easy and enjoyable",
                                        "Our holder mount is compatible with almost all electronic products on the market. As long as the size of your electronic product is between 4.7-12.9 inches, it can be stably fixed behind the car seat",
                                    ],
                                }
                            ]
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/54716750-f659-47f5-b30c-e7b5811b27f8.__CR0,0,970,300_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/85ac6be6-d2eb-4698-a561-298f00f805ce.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Wide Compatibility",
                                    text: "Tryone car tablet mount holder is compatible with almost all autos on the market, as long as your headrest metal strip width is 1.5-7.9 inches, you can use it",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/2e078eae-4080-4023-9504-06c3fab80db9.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Rubber Protection",
                                    text: "On the contact surface with the car and equipment, we are equipped with soft rubber, so that it will not wear your car or equipment",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/f53557db-b05e-4391-988f-27fb583e4a44.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Toolless Installation",
                                    text: "You can complete the installation of Tryone tablet holder mount without any tools",
                                },
                            ],
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/be08750f-3689-49bf-87c6-4266192531ae.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "360° Adjustment",
                                    text: "Two car mount holders support 360° adjustment, and passengers in the back row can adjust to the appropriate direction at will",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/ab054ce3-08bd-4e51-8286-1fbc1604367c.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Convenient to Charge",
                                    text: "Tablet holder mounts are provided with a position that can be inserted into the charging power cord, which can be charged at the same time during your use",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/c4d5b02e-842d-4a7c-9648-cbbc331fc61b.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Packing List",
                                    text: "Tablet holder Clamp * 2\nBracket * 1",
                                },
                            ],
                        },
                    ]
                },
                {
                    id: 13,
                    title: 'Car Tablet Holder Mount for iPad: Tryone Headrest Tablet Stand for Car Back Seat Compatible with iPad Pro Air Mini | Galaxy Tab | Kindle Fire HD | Switch Lite or Other 4.7 -12.9" Devices',
                    price: "$39.99",
                    category: "Tablet Headrest Mount",
                    url: 'https://www.amazon.com/Car-Tablet-Holder-Mount-iPad/dp/B0B19WXWVN',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/712Z5-t0tRL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71rXvzM88uL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71iiaNitlpL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71jkJUz4foL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71Nk5EYQ9-L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/81JU3J1RrxL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/ddad9bdf-8689-4dd2-a9db-1ed142be2672/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black",
                        "Compatible Devices": "Android Cellphones/ iPhones, Switch/ Switch OLED, Android Tablets/ iPads",
                        "Mounting Type": "Headrest",
                        "Special Feature": "Wide Compatibility, Rotatable, Easy Installation, Hand Free, Adjustable",
                        "Material": "Aluminum, Acrylonitrile Butadiene Styrene",
                        "Vehicle Service Type": "Automobile",
                        "ASIN": "B0B19WXWVN",
                        "SKU": "RH-01",
                    },
                    tips: [
                        '【Best Viewing Angle】 This Tryone Car Tablet Holder is specially designed for the rear seat, the tablet holder can be adjusted left and right, with 360° rotation and 75° tilt, whether it is one passenger, two passengers or three passengers, this headrest mount will provide the best viewing position',
                        '【Wide Compatibility】 The headrest tablet holder is compatible with all 4.7-12.9 inch devices, such as iPad Pro 9.7, 10.5, 2022 Newest iPad Pro 11, 12.9, iPad mini 3 4 5 6, iPad Air 2 3, iPad 2 3 4 5 6, iPhone 13 12 Pro XS Max XR X 6 7 8 Plus, Galaxy Tab, Surface Pro, Pixel C, Nexus 7 9, Tab E 4, Fire HD, Kindle, Switch and more',
                        '【Fits Most Car】 The Car Tablet Holder is very easy to install and remove without tools. The two sides hooks fit headrest bars with a maximum diameter of 0.5"/1.3cm, compatible headrest spacing with a minimum width of 18.9inch/48cm, a maximum width of 31.9inch/81cm',
                        '【Stable & Durable】 Tryone tablet holder for car is made of high-strength material, the telescopic rod is made of aluminium, and the clamp is made of ABS material, which can support your tablet stably even on bumpy roads',
                        '【Great for Travel by Car】 It is your road trip essentials stuff, on the trip, you can use it to soothe your kids, in addition to the headrest mount, we also come with two headrest hooks that can be used to hang your backpack',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/50b05545-3212-438d-825f-70094af8af89.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/112db8a5-7075-45d1-8c83-6bc3625f710a.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'threeSet',
                            data: [
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/70c28036-ea41-4578-92c4-e6405339ee56.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Left & Right Adjustment",
                                    text: "Benefit from the quick lock structure, the iPad clip can move left and right",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/bfda2eca-5756-4cec-a90e-12cd54d5574e.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "Tilt Adjustment",
                                    text: "Supports 75° tilt adjustment, and the angle can be adjusted according to different viewing angles",
                                },
                                {
                                    url: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/f7529a9c-cdc6-48bf-9732-6f38613d217c.__CR0,0,300,300_PT0_SX300_V1___.jpg",
                                    title: "360 ° Rotation",
                                    text: "The tablet holder support 360 ° adjustment, and rear row passengers can adjust the appropriate direction at will",
                                },
                            ],
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/df5638ca-93e8-4453-a2ce-7e14039c2da2.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/1e5997ae-6522-40d0-9bb3-cf89299d19a1.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
                {
                    id: 14,
                    title: 'Car Cup Holder Phone Mount: Tryone Universal Auto Cell Phone Stand with Drink Expand Cup Holder for Truck | SUV | Automobile Compatible with iPhone & Samsung and Other Android Smartphone',
                    price: "$26.99",
                    category: "Car Cup Holder",
                    url: 'https://www.amazon.com/Car-Cup-Holder-Phone-Mount/dp/B09YNTJW1P',
                    images: [
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/716R4fsmM9L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71dbR+KMFYL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71cD0Pga62L.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/71-Dck75bML.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/81N5VeKViZL.jpg',
                        },
                        {
                            type: 'image',
                            url: 'https://m.media-amazon.com/images/I/81Fm6D6XMIL.jpg',
                        },
                        {
                            type: 'video',
                            url: 'https://m.media-amazon.com/images/S/vse-vms-transcoding-artifact-us-east-1-prod/7bf7a555-62cc-4ca2-a4b4-503556f1acbc/default.jobtemplate.mp4.480.mp4',
                        },
                    ],
                    properties: {
                        "Brand": "Tryone",
                        "Color": "Black",
                        "Compatible Devices": "Smartphones",
                        "Special Feature": "Rotatable, Hand Free, Adjustable",
                        "Material": "Acrylonitrile Butadiene Styrene",
                        "Vehicle Service Type": "Automobile",
                        "ASIN": "B09YNTJW1P",
                        "SKU": "ANJ-109",
                    },
                    tips: [
                        '【2 in 1 Car Cup Phone Holder】The most unique design of the cup holder phone mount is that it can hold your phone while also holding your cup. The bottom of the cup holder is stable due to its triangular design. Made of high-quality ABS material, high strength, durable and toughness, so as to give you a better experience',
                        '【Universal Compatibility】The phone mount is compatible with all 4"-7" screen smartphones, such as iPhone 13 12 11 Pro Max Mini XS XR X 8 Plus 8 7 Plus 7 6S Plus 6S 6 SE, Galaxy S10e S10+ S10 S9+ S9 S8+ S8 S7 Edge Note 9 8, support for LG Moto Google Pixel Moto, etc',
                        '【Suppotr Thick Phone Case】This cup holder phone mount can prevent any shaking, what’s more, the phone holder supports otterbox and thick case, so you don’t need to take off your phone case to use it',
                        '【360°Rotating & Adjustable】The phone holder can be flexibly rotated 360°, and supports both horizontal and vertical screens of mobile phones, allowing you to adjust to the most convenient and comfortable position. The cup holder fits cylindrical bottles of different sizes up to 3.3 inches in diameters, such as cans, coffee mugs, water cups, and juice bottles',
                        '【Tool-less Installation】After inserting our cup holder, just rotate the vane clockwise to expand the size of the bottom adapter and firmly fix the base. The insert expands from 0.24" to 4.09" in diameter to fit most automotive centre console cup holders and works great for almost all cars, golf carts, trucks, and RVs',
                    ],
                    contents: [
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/df47a640-fd8f-47ea-b74a-816620d9e069.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/00000793-d12d-457c-a568-c0288152d0b4.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/662f381c-c67a-4e91-ac0e-2ff12b744bbc.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/87058e5d-9d05-4cc7-9562-a3f77ced1112.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/2018583c-9c3f-4b65-b365-6750d926944c.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                        {
                            type: 'image',
                            data: 'https://m.media-amazon.com/images/S/aplus-media-library-service-media/2c17ab0b-71e6-4679-befd-8dcc6abb8bd0.__CR0,0,970,600_PT0_SX970_V1___.jpg',
                        },
                    ]
                },
            ]
        }
    }
}