<template>
  <form class="contact-form-1">
    <div class="form-group">
      <input
          type="text"
          name="userName"
          placeholder="Your Name"
          required
          v-model="contact.userName"
      />
    </div>

    <div class="form-group">
      <input
          type="email"
          name="email"
          placeholder="Email Address"
          required
          v-model="contact.email"
      />
    </div>

    <div class="form-group">
      <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          v-model="contact.phone"
      />
    </div>


    <div class="form-group">
      <input
          type="text"
          name="subject"
          placeholder="Subject"
          required
          v-model="contact.subject"
      />
    </div>

    <div class="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                    v-model="contact.message">
                </textarea>
    </div>

    <div class="form-group">
      <button class="btn-default btn-large" @click.prevent="save">Submit Now</button>
    </div>

    <div class="form-group" v-html="showResult ? result : null"/>
  </form>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      showResult: false,
      result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`,
      contact: {
        siteName: 'Fenolical',
        userName: null,
        email: null,
        phone: null,
        subject: null,
        message: null
      }
    }
  },
  methods: {
    save() {
      this.showResult = false;

      if (!/^[\d\D]{3,32}$/.test(this.contact.userName)) {
        this.result = `<p class="error-message">Name is invalid.</p>`
        this.showResult = true
        return
      }

      if (!/^[\\.a-zA-Z0-9_-]+[@＠][a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.contact.email) || this.contact.email.length > 200) {
        this.result = `<p class="error-message">Email is invalid.</p>`
        this.showResult = true
        return
      }

      if (!/^[\d\D]{3,200}$/.test(this.contact.subject)) {
        this.result = `<p class="error-message">Subject is invalid.</p>`
        this.showResult = true
        return
      }

      if (!/^[\d\D]{3,2000}$/.test(this.contact.message)) {
        this.result = `<p class="error-message">Message is invalid.</p>`
        this.showResult = true
        return
      }

      this.$axios.post('/api/website/contact', this.contact).then(() => {
        this.result = `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
        this.showResult = true;
      }).catch(err => {
        this.result = `<p class="error-message">${err.message}</p>`
        this.showResult = true;
      });

    },
  }
}
</script>