<template>
  <Layout>
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
                text-align="center"
                subtitle="Installation"
                title="Get Your Installation Guide"
                data-aos="fade-up"
            />
          </div>
          <div class="col-lg-12 mb--40 text-center" data-aos="fade-up">
            <button class="btn-default btn-large" onClick="parent.location='https://www.youtube.com/watch?v=YsLrBcfl6PY'" formtarget="_blank">Tryone Solor Phone Mount</button>
          </div>
          <div class="col-lg-12 mb--40 text-center" data-aos="fade-up">
            <button class="btn-default btn-large" onClick="parent.location='https://www.youtube.com/watch?v=QlRGi04ok88'" formtarget="_blank">Tryone Magsafe Phone Mount</button>
          </div>
        </div>
        <!--<div class="row mt&#45;&#45;40 row&#45;&#45;15">
          <div class="col-12">

          </div>
          &lt;!&ndash;<div class="col-lg-5 mt_md&#45;&#45;30 mt_sm&#45;&#45;30">
              <GoogleMap/>
          </div>&ndash;&gt;
        </div>-->
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import SectionTitle from "@/components/elements/sectionTitle/SectionTitle.vue";
import Layout from "@/components/common/Layout.vue";

export default {
  name: "InstallationPage",
  components: {Layout, SectionTitle}
}
</script>

<style scoped>

</style>