<template>
  <div>
    <div class="row">
      <img v-if="current.type === '图片'" class="radius w-100" :src="current.url" alt="Corporate Image"/>
      <iframe v-if="current.type === '视频'" width="500" height="500" :src="current.url"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
    <div class="row row--0 pt-2">
      <span v-for="image of images" :class="['col-1','image-album', image.url === current.url ? 'active' : '']"
            @mouseover="change(image)">
        <img v-if="image.type === '图片'" :src="image.url" alt="Corporate Image"/>
        <Icon v-else-if="image.type === '视频'" size="40" name="youtube" icon-class="color-pink"/>
      </span>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon";

export default {
  name: "ImageAlbum",
  components: {Icon},
  props: {
    images: {
      type: Array,
    }
  },
  data() {
    return {
      current: this.images[0]
    }
  },
  methods: {
    change(image) {
      this.current = image;
    }
  }
}
</script>

<style scoped>

</style>