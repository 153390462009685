<template>
  <div>
    <div class="row row--15">
      <div class="col-lg-12">
        <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
            id="pills-tab"
            role="tablist"
            data-aos="fade-up">
          <li class="nav-item list-item"
              role="presentation"
              v-for="filter in filters"
              :key="`tab-button-${filter}`">
            <button class="nav-link"
                    :class="{'active': filter === activeTab}"
                    :id="`pills-${filter}-tab`"
                    data-bs-toggle="pill"
                    :data-bs-target="`#pills-${filter}`"
                    type="button"
                    role="tab"
                    :aria-controls="`pills-${filter}`"
                    :aria-selected="filter === activeTab ? 'true' : 'false'"
                    @click="goto(filter)">
              {{ filter }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade"
           :class="{'show active': filter === activeTab}"
           :id="`pills-${filter}`"
           role="tabpanel"
           :aria-labelledby="`pills-${filter}-tab`"
           v-for="filter in filters"
           :key="`tab-pane-${filter}`">
        <div class="row row--15">
          <template v-if="filter === 'all' || filter === product.websiteCategory.name" v-for="product in products">
          <!--<template v-for="product in products">-->
            <div :class="`${column} product`" data-aos="fade-up">
              <div class="rwt-card">
                <div class="inner">
                  <div class="thumbnail">
                    <figure class="card-image">
                      <router-link :to="`/product/detail/${product.asin}`">
                        <img class="img-fluid" :src="product.albums[0].url" alt="product-01"/>
                      </router-link>
                    </figure>
                    <router-link :to="`/product/detail/${product.asin}`" class="rwt-overlay"></router-link>
                  </div>
                  <div class="content">
                    <h5 class="title mb--10">
                      <router-link :to="`/product/detail/${product.asin}`">
                        {{ product.title }}
                      </router-link>
                    </h5>
                    <span class="subtitle b2 text-capitalize">{{ product.websiteCategory.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!--<template v-if="filter !== 'all' && filter === product.websiteCategory.name"
                    v-for="(product, index) in products">
            <div :class="`${column} product`">
              <div class="rwt-card">
                <div class="inner">
                  <div class="thumbnail">
                    <figure class="card-image">
                      <router-link :to="`/product/detail/${product.id}`">
                        <img class="img-fluid" :src="product.albums[0].url" alt="product-01"/>
                      </router-link>
                    </figure>
                    <router-link :to="`/product/detail/${product.asin}`" class="rwt-overlay"></router-link>
                  </div>
                  <div class="content">
                    <h5 class="title mb&#45;&#45;10">
                      <router-link :to="`/product/detail/${product.asin}`">
                        {{ product.title }}
                      </router-link>
                    </h5>
                    <span class="subtitle b2 text-capitalize">{{ product.price }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>-->
        </div>
      </div>
    </div>
<!--    <div class="row row&#45;&#45;15">
      <div class="col-lg-12 text-center">
        <div class="rwt-load-more text-center mt&#45;&#45;60" data-aos="fade-up">
          <a :class="buttonClass" href="#">
            Load More
            <Icon name="loader" icon-class="icon"/>
          </a>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
  name: 'Product',
  components: {Icon},
  props: {
    products: [],
    buttonClass: {
      type: String,
      default: 'btn btn-default btn-large btn-icon'
    },
    column: {
      type: String,
      default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
    }
  },
  computed: {
    filters() {
      let filters = ['all'];
      for (let product of this.products) {
        if (!filters.includes(product.websiteCategory.name)) {
          filters.push(product.websiteCategory.name);
        }
      }
      return filters;
    }
  },
  data() {
    return {
      activeTab: this.$route.params.category || 'all'
    }
  },
  methods: {
    goto(category) {
      this.activeTab = category
      this.$router.replace(`/product/${category}`)
    }
  },
}
</script>